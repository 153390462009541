// import { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import debounce from 'lodash.debounce';

// // Custom hook: useSearchAndFollow
// const useSearchAndFollow = () => {
//   // State for input and search query
//   const [inputValue, setInputValue] = useState('');
//   const [searchQuery, setSearchQuery] = useState('');
  
//   // State for loading, users data, and errors
//   const [loadingUsers, setLoadingUsers] = useState(false);
//   const [users, setUsers] = useState([]);
//   const [loadingFollow, setLoadingFollow] = useState({});
//   const [error, setError] = useState(null);

//   // Memoized debounced function to set search query
//   const debouncedSetSearchQuery = useMemo(() => 
//     debounce((value) => setSearchQuery(value), 300), []
//   );

//   // Handle input change and update search query with debounce
//   const handleInputChange = useCallback((e) => {
//     const { value } = e.target;
//     setInputValue(value);

//     if (value.trim() === '') {
//       // Clear users and errors if input is empty
//       setUsers([]);
//       setError(null);
//     } else {
//       // Debounce the search query update
//       debouncedSetSearchQuery(value);
//     }
//   }, [debouncedSetSearchQuery]);

//   // Clear input and related states
//   const clearInput = useCallback(() => {
//     setInputValue('');
//     setSearchQuery('');
//     setUsers([]);
//     setError(null);
//   }, []);

//   // Fetch users when search query changes
//   useEffect(() => {
//     const fetchUsers = async () => {
//       if (searchQuery.trim() === '') {
//         setUsers([]);
//         return;
//       }

//       setLoadingUsers(true);
//       setError(null);

//       try {
//         const token = localStorage.getItem('token');
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL_GENERAL}/discovery`, {
//             headers: { Authorization: `Bearer ${token}` },
//             params: { search: searchQuery },
//           }
//         );

//         if (response.status === 200 && response.data?.data?.users) {
//           setUsers(response.data.data.users);
//         } else {
//           setUsers([]);
//           setError('No users found for this search.');
//         }
//       } catch (err) {
//         console.error('Error performing search:', err);
//         setUsers([]);
//         setError('Error performing search. Please try again.');
//       } finally {
//         setLoadingUsers(false);
//       }
//     };

//     fetchUsers();
//   }, [searchQuery]);

//   // Handle follow or unfollow action
//   const handleFollowOrUnfollow = useCallback(async (userId, isFollowing) => {
//     setLoadingFollow((prevState) => ({ ...prevState, [userId]: true }));
//     setError(null);

//     try {
//       const token = localStorage.getItem('token');
//       const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${
//         isFollowing ? 'unfollow' : 'follow'
//       }`;

//       const method = isFollowing ? 'delete' : 'post';

//       const response = await axios({
//         method,
//         url: apiUrl,
//         data: { userId },
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       if (response.status === 200 || response.status === 201) {
//         setUsers((prevUsers) =>  prevUsers.map((user) => user.id === userId ? { ...user, isFollowing: !isFollowing } : user )
//         );
//       } else {
//         setError('Unable to complete the action. Please try again.');
//       }
//     } catch (err) {
//       console.error('Error in follow/unfollow:', err);
//       setError('Error performing follow/unfollow action. Please try again.');
//     } finally {
//       setLoadingFollow((prevState) => ({ ...prevState, [userId]: false }));
//     }
//   }, []);

//   // Return all necessary state and handlers
//   return {
//     inputValue,
//     handleInputChange,
//     clearInput,
//     users,
//     loadingUsers,
//     error,
//     handleFollowOrUnfollow,
//     loadingFollow,
//   };
// };

// export default useSearchAndFollow;









import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

// Custom hook: useSearchAndFollow
const useSearchAndFollow = () => {
  const [inputValue, setInputValue] = useState(''); // Input field value
  const [searchQuery, setSearchQuery] = useState(''); // Query sent to API
  const [loadingUsers, setLoadingUsers] = useState(false); // Loading state for users
  const [users, setUsers] = useState([]); // Fetched users
  const [loadingFollow, setLoadingFollow] = useState({}); // Loading state for follow/unfollow
  const [error, setError] = useState(null); // Error state

  // Memoized debounced function to update the search query
  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300), // 300ms debounce to avoid excessive API calls
    []
  );

  // Handle input change and search query
  const handleInputChange = useCallback(
    (e) => {
      const { value } = e.target;
      setInputValue(value);

      if (value.trim() === '') {
        // If input is cleared, clear the users and stop any ongoing search
        setUsers([]);
        setError(null); // Clear any previous error
        debouncedSetSearchQuery.cancel(); // Cancel debounced API call if any
      } else {
        // Otherwise, trigger the search after debounce
        debouncedSetSearchQuery(value);
      }
    },
    [debouncedSetSearchQuery]
  );

  // Clear input and reset all related states
  const clearInput = useCallback(() => {
    setInputValue('');
    setSearchQuery('');
    setUsers([]); // Clear users list immediately
    setError(null);
  }, []);

  // Fetch users from API when searchQuery changes
  useEffect(() => {
    const fetchUsers = async () => {
      // If search query is empty, avoid fetching
      if (!searchQuery.trim()) {
        setUsers([]); // Clear users when search query is cleared
        return;
      }

      setLoadingUsers(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_GENERAL}/discovery`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { search: searchQuery },
          }
        );

        if (response.status === 200 && response.data?.data?.users) {
          setUsers(response.data.data.users);
        } else {
          setUsers([]);
          setError('No users found for this search.');
        }
      } catch (err) {
        console.error('Error performing search:', err);
        setUsers([]);
        setError('Error performing search. Please try again.');
      } finally {
        setLoadingUsers(false);
      }
    };

    // Trigger API call when searchQuery changes
    if (searchQuery.trim()) {
      fetchUsers();
    }
  }, [searchQuery]);

  // Handle follow or unfollow action
  const handleFollowOrUnfollow = useCallback(
    async (userId, isFollowing) => {
      setLoadingFollow((prevState) => ({ ...prevState, [userId]: true }));
      setError(null);

      try {
        const token = localStorage.getItem('token');
        const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${
          isFollowing ? 'unfollow' : 'follow'
        }`;

        const method = isFollowing ? 'delete' : 'post';

        const response = await axios({
          method,
          url: apiUrl,
          data: { userId },
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200 || response.status === 201) {
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === userId ? { ...user, isFollowing: !isFollowing } : user
            )
          );
        } else {
          setError('Unable to complete the action. Please try again.');
        }
      } catch (err) {
        console.error('Error in follow/unfollow:', err);
        setError('Error performing follow/unfollow action. Please try again.');
      } finally {
        setLoadingFollow((prevState) => ({ ...prevState, [userId]: false }));
      }
    },
    []
  );

  // Return all necessary state and handlers
  return {
    inputValue,
    handleInputChange,
    clearInput,
    users,
    loadingUsers,
    error,
    handleFollowOrUnfollow,
    loadingFollow,
  };
};

export default useSearchAndFollow;
