// import React from 'react';
// import { FaSearch, FaUserAltSlash, FaTimes, FaSpinner } from 'react-icons/fa';
// import { ImSpinner8 } from 'react-icons/im';
// import { Link } from 'react-router-dom';
// import useSearchAndFollow from './useSearchAndFollow';

// const Search = () => {
//   const {
//     inputValue,
//     handleInputChange,
//     clearInput,
//     users,
//     loadingUsers,
//     error,
//     handleFollowOrUnfollow,
//     loadingFollow,
//   } = useSearchAndFollow();

//   return (
//     <div className="max-w-full md:max-w-xl mx-auto h-screen flex flex-col md:flex-row">
//       <div className="flex justify-center flex-grow bg-neutral-800 text-white p-4 md:p-2 rounded-md flex-col">
//         <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
//           {/* Search Input UI */}
//           <div className="relative mx-4 mt-4 md:mx-6">
//             <input
//               type="text"
//               value={inputValue}
//               onChange={handleInputChange}
//               placeholder="Search..."
//               className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
//             />
//             <FaSearch className="absolute top-3 left-3 text-gray-400" />
//             {inputValue && (
//               <FaTimes
//                 className="absolute top-3 right-3 text-gray-400 cursor-pointer"
//                 onClick={clearInput}
//               />
//             )}
//           </div>

//           {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

//           {/* User List UI */}
//           <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 h-64">
//             {loadingUsers ? (
//               <div className="flex items-center justify-center h-full">
//                 <ImSpinner8 className="text-gray-400 animate-spin" size={40} />
//               </div>
//             ) : users.length === 0 ? (
              // <div className="flex flex-col items-center justify-center h-full">
              //   <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
              //   <p className="text-gray-400 mt-4 animate-pulse">No recent searches.</p>
              // </div>
//             ) : (
//               users.map((user) => (
//                 <div
//                   key={user.id}
//                   className=" rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md"
//                 >
//                   <div className="flex items-start gap-4">
//                     <img
//                       src={user.profilePicture || "https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png"}
//                       alt=""
//                       className="block h-12 w-12 rounded-full object-cover"
//                     />
//                     <Link to={`/user/profile/${user.id}`}>
//                       <div className="h-full flex flex-col items-start">
//                         <span className="text-lg font-semibold text-white">{user.userName}</span>
//                         <span className="text-sm text-gray-300">{user.firstName} {user.lastName}</span>
//                       </div>
//                     </Link>
//                   </div>
//                   <button
//                     className={`ml-2 px-4 py-1.5 font-semibold text-sm rounded-md text-center transition-colors duration-300 ease-in-out ${
//                       user.isFollowing ? "bg-gray-500 text-white" : "bg-blue-600 text-white"
//                     }`}
//                     onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
//                     disabled={loadingFollow[user.id]}
//                   >
//                     {loadingFollow[user.id] ? (
//                       <FaSpinner className="animate-spin" />
//                     ) : (
//                       user.isFollowing ? "Following" : "Follow"
//                     )}
//                   </button>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;









import React from 'react';
import { FaSearch, FaTimes, FaSpinner, FaUserAltSlash } from 'react-icons/fa';
import useSearchAndFollow from './useSearchAndFollow';

const Search = () => {
  const {
    inputValue,
    handleInputChange,
    clearInput,
    users,
    loadingUsers,
    error,
    handleFollowOrUnfollow,
    loadingFollow,
  } = useSearchAndFollow();

  return (
    <div className="max-w-xl mx-auto h-screen flex flex-col">
      <div className="flex justify-center flex-grow bg-neutral-800 text-white p-4 md:p-2 rounded-md flex-col">
        <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
          {/* Search Input UI */}
          <div className="relative mx-4 mt-4 md:mx-6">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search..."
              className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
            />
            <FaSearch className="absolute top-3 left-3 text-gray-400" />
            {inputValue && (
              <FaTimes
                className="absolute top-3 right-3 text-gray-400 cursor-pointer"
                onClick={clearInput}
              />
            )}
          </div>

          {/* Error Handling */}
          {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

          {/* User List */}
          <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 h-64">
            {loadingUsers ? (
              <div className="flex items-center justify-center h-full">
                <FaSpinner className="text-gray-400 animate-spin" size={40} />
              </div>
            ) : users.length === 0 ? (
              
              <div className="flex flex-col items-center justify-center h-full">
                  <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
                <p className="text-gray-400 mt-4">No users found.</p>
              </div>
            ) : (
              users.map((user) => (
                <div
                  key={user.id}
                  className="rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md"
                >
                  <div className="flex items-start gap-4 cursor-pointer">
                    <img
                      src={user.profilePicture || 'https://via.placeholder.com/300'}
                      alt={user.userName}
                      className="h-12 w-12 rounded-full object-cover"
                    />
                    <div className="h-full flex flex-col">
                      <span className="text-lg font-semibold text-white">{user.userName}</span>
                      <span className="text-sm text-gray-300">{user.firstName} {user.lastName}</span>
                    </div>
                  </div>
                  <button
                    className={`ml-2 px-4 py-1.5 font-semibold text-sm rounded-md transition-colors duration-300 ease-in-out ${
                      user.isFollowing ? 'bg-gray-500 text-white' : 'bg-blue-600 text-white'
                    }`}
                    onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
                    disabled={loadingFollow[user.id]}
                  >
                    {loadingFollow[user.id] ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      user.isFollowing ? 'Following' : 'Follow'
                    )}
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
