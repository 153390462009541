// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { useAuth } from './AuthContext'; // Import the useAuth hook from your context file

// const PrivateRoute = () => {
//   const { isAuthenticated } = useAuth();

//   return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
// };

// export default PrivateRoute;






import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  //  const isAuthenticated = !!localStorage.getItem("token");
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
