import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ShareReelsVideo = () => {
  const { id } = useParams(); // Get the video ID from the URL
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/videos/${id}`);
        setVideo(response.data);
      } catch (err) {
        setError('Error fetching video');
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return video ? (
    <div>
      <h1>{video.title}</h1>
      {/* <video src={video.videoUrl} controls /> */}
      {/* Add other video details */}
    </div>
  ) : (
    <div>Video not found</div>
  );
};

export default ShareReelsVideo;
